import {
  PostCardFragmentFragment,
  RepostCardFragmentFragment,
  StreamPostCardFragmentFragment,
  TournamentPostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { paths } from '@/routes-utils/paths.ts';
import { PrefetchPageLinks } from 'react-router';
import { Feed, FeedProps } from '../feed/feed.tsx';
import { PolymorphicPostCard } from '../polymorphic-post-card.tsx';
import { postFeedGap } from './post-feed.css.ts';
import { Separator } from '@synoptic/ui-kit/separator/separator.js';

type FeedItem =
  | PostCardFragmentFragment
  | RepostCardFragmentFragment
  | StreamPostCardFragmentFragment
  | TournamentPostCardFragmentFragment;

export type PostFeedProps = Omit<FeedProps<FeedItem, never>, 'itemContent'> & {
  gap?: number;
};

const itemContent = (idx: number, post: FeedItem) => (
  <>
    {idx > 0 && post.__typename === 'StreamPost' && <Separator />}
    <PolymorphicPostCard post={post} linkToPost />
  </>
);

export const PostFeed = (props: PostFeedProps) => {
  return (
    <>
      <Feed
        getBackToTopLabel={(n) => `${n} New posts`}
        gap={props.gap || postFeedGap}
        computeItemKey={(_idx, post) => post.id}
        itemContent={itemContent}
        {...props}
      />
      <PrefetchPageLinks page={paths.post} />
    </>
  );
};
